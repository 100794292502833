@use 'mixins/brand' as *;

:root {
  // default colors
  --theme-rgb-color: #{rgbValues(#f69d23)};
  --theme-rgb-color-dark: #{rgbValues(#232323)};
  --theme-rgb-color-font-alt: #{rgbValues(#232323)};

  &.alvogen {
    --theme-rgb-color: #{rgbValues(#fc0)};
  }

  &.alvotech {
    --theme-rgb-color: #{rgbValues(#f69d23)};
  }

  &.lotus {
    --theme-rgb-color: #{rgbValues(#fc0)};
  }

  &.adalvo {
    --theme-rgb-color: #{rgbValues(#da3731)};
    --theme-rgb-color-dark: #{rgbValues(#1c1f3f)};
    --theme-rgb-color-font-alt: #{rgbValues(#fff)};
  }

  &.almatica {
    --theme-rgb-color: #{rgbValues(#0072bc)};
    --theme-rgb-color-dark: #{rgbValues(#595a5c)};
    --theme-rgb-color-font-alt: #{rgbValues(#fff)};
  }

  &.almaject {
    --theme-rgb-color: #{rgbValues(#97b3c4)};
    --theme-rgb-color-dark: #{rgbValues(#2f2f2e)};
  }
}
