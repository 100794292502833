@use 'styles/config' as *;

$page-transition-z-index: 999999;

.pageTransition {
  &__container {
    @include container;
  }

  &__logo {
    display: none;
    align-items: center;
    justify-content: center;

    position: fixed 0;
    z-index: $page-transition-z-index;

    pointer-events: none;

    svg {
      width: 18rem;

      animation: pulse 1s infinite alternate ease-out;

      path {
        // fill: #fff;
        @include brandColor(fill);
      }
    }

    // Alvotech logo
    :global(.alvotech) & > svg {
      path:nth-child(1) {
        fill: #f89e1c;
      }
      path:nth-child(2) {
        fill: #fff;
      }
    }

    // Almatica logo
    :global(.almatica) & > svg {
      // width: min(25rem, 100%);

      path {
        fill: #fff;
      }

      path:nth-child(9),
      path:nth-child(10),
      path:nth-child(11) {
        fill: brand();
      }
    }
  }

  &__brand,
  &__mask {
    display: none;
    position: fixed 0;
    z-index: $page-transition-z-index - 1;

    background: #111;

    :global(.adalvo) &,
    :global(.almatica) & {
      background-color: brandDark();
    }
  }

  &__brand {
    z-index: $page-transition-z-index - 2;
    @include brandColor;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.35;
  }

  100% {
    opacity: 1;
  }
}
