@use 'theme';
@use 'config' as *;

:root {
  font-size: 62.5%;

  @media (max-height: $scale-height-lg) and (min-aspect-ratio: 3/2) {
    font-size: 62.5% * $scale-lg;
  }

  @media (max-height: $scale-height-md) and (min-aspect-ratio: 3/2) {
    font-size: 62.5% * $scale-md;
  }

  @media (max-height: $scale-height-sm) and (min-aspect-ratio: 3/2) {
    font-size: 62.5% * $scale-sm;
  }
}

// focus testing
// *:focus {
//   outline: solid 3px red !important;
// }

// * {
//   outline: 1px solid red;
// }

html,
body {
  min-height: 100vh;

  background: $color-background;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  // always show the vertical scrollbar so that content doesn't jump
  overflow-y: scroll;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;

  &.no-scroll {
    overflow: hidden;
  }
}

// inherited from <html>
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;

  margin: 0;

  font-family: $font-family;
  font-size: responsive($font-size-min, $font-size);
  line-height: $line-height;
  color: $color-font;
  text-wrap: pretty;

  // iOS on orientation change
  text-size-adjust: 100%;

  &.no-scroll {
    height: 100vh;
    overflow: hidden;
  }

  &.dark {
    color: #fff;
  }

  &.SRLOpened {
    margin-right: 0 !important;
    height: 100vh;
    overflow: hidden;
  }
}

#SRLLightbox .SRLImage {
  max-width: 90%;
  max-height: 100%;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

// placeholders
input,
textarea,
select {
  &::-webkit-input-placeholder {
    color: $color-placeholder;

    :global(body.brand) &,
    :global(body.dark) & {
      color: $color-placeholder-dark;
    }
  }

  &::-moz-placeholder {
    opacity: 1;
    color: $color-placeholder;

    :global(body.brand) &,
    :global(body.dark) & {
      color: $color-placeholder-dark;
    }
  }

  &:-ms-input-placeholder {
    color: $color-placeholder;

    :global(body.brand) &,
    :global(body.dark) & {
      color: $color-placeholder-dark;
    }
  }
}

// selection color
::selection {
  color: #fff;
  @include brandColor;
}

#CybotCookiebotDialog {
  background: rgba($color-background-alt, 1) !important;

  &PoweredbyLink {
    display: none;
  }

  &Detail {
    padding: 10px !important;
  }

  #CybotCookiebotDialogBody {
    padding: 20px 0 !important;

    &Content {
      padding: 0 20px 20px !important;

      &Title {
        color: #fff;
        margin-top: 5px !important;
        margin-bottom: 15px !important;
      }
      &Text {
        color: rgba(#fff, 0.8);
        line-height: 1.35;
      }
    }

    &Buttons {
      padding: 0 20px 0 0 !important;
    }

    &Button {
      &Decline,
      &Accept {
        padding: 7px 5px !important;
        border: 0;
      }

      &Accept {
        @include brandColor(background);
        // font-weight: bold;
        color: $color-dark;
      }

      &Decline {
        color: #ddd;
        background: #000;
        border: 0px solid rgba(#fff, 0.5);
      }
    }
  }
}

.CookieDeclaration {
  @include render-copy;

  a {
    @include anchor;
  }
}
